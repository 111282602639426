import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    ListItem,
    UnorderedList
} from "@chakra-ui/react";

function HowToAccordion() {
    return (
        <Accordion allowToggle>
            <AccordionItem>
                <h2>
                    <AccordionButton>
                        <Box flex='1' textAlign='left'>
                            How to use Zendesk SLA Report Table Generator
                        </Box>
                        <AccordionIcon/>
                    </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                    <UnorderedList>
                        <ListItem>Authenticate with Zendesk</ListItem>
                        <ListItem>Set date range for the closed tickets. Open, pending and on-hold tickets are
                            always shown. Archived tickets are never shown</ListItem>
                        <ListItem>Select organization from dropdown</ListItem>
                        <ListItem>Press 'Get Tickets' to retrieve the tickets from Zendesk</ListItem>
                        <ListItem>Press 'Copy Table' to copy table HTML to clipboard</ListItem>
                        <ListItem>Paste in Google Docs</ListItem>
                    </UnorderedList>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    )
}

export default HowToAccordion;
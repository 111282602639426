import {Routes, Route} from "react-router-dom";
import './App.css';
import Home from "./pages/Home";
import {ChakraProvider, Flex, useColorModeValue} from '@chakra-ui/react'

function App() {
    return (
        <ChakraProvider>
            <Flex
                minH={'100vh'}
                align={'center'}
                justify={'center'}
                bg={useColorModeValue('gray.50', 'gray.800')}>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                </Routes>
            </Flex>
        </ChakraProvider>
    );
}

export default App;

import TableRow from "./TableRow";

function Table(props) {
    const tickets = props.tickets;

    return (
        <b style={{"fontWeight": "normal"}} id="docs-internal-guid-bdcfb0ab-7fff-d0f3-d59f-a1388a153651">
            <div dir="ltr" style={{"marginLeft": "0pt"}} align="left">
                <table style={{"border": "none", "borderCollapse": "collapse"}}>
                    <colgroup>
                        <col width="43"/>
                        <col width="70"/>
                        <col width="253"/>
                        <col width="80"/>
                        <col width="98"/>
                        <col width="94"/>
                    </colgroup>
                    <tbody>
                    <tr style={{"height": "24pt"}}>
                        <td style={{
                            "borderLeft": "solid #d9d9d9 0.5pt",
                            "borderRight": "solid #d9d9d9 0.5pt",
                            "borderBottom": "solid #d9d9d9 0.5pt",
                            "borderTop": "solid #d9d9d9 0.5pt",
                            "verticalAlign": "top",
                            "backgroundColor": "#baead7",
                            "padding": "5pt 5pt 5pt 5pt",
                            "overflow": "hidden",
                            "overflowWrap": "break-word"
                        }}>
                            <p dir="ltr" style={{"lineHeight": "1.44", "marginTop": "0pt", "marginBottom": "0pt"}}><span
                                style={{
                                    "fontSize": "11pt",
                                    "fontFamily": "'Helvetica Neue',sans-serif",
                                    "color": "#000000",
                                    "backgroundColor": "transparent",
                                    "fontWeight": "700",
                                    "fontStyle": "normal",
                                    "fontVariant": "normal",
                                    "textDecoration": "none",
                                    "verticalAlign": "baseline",
                                    "whiteSpace": "pre-wrap"
                                }}>Prio</span>
                            </p>
                        </td>
                        <td style={{
                            "borderLeft": "solid #d9d9d9 0.5pt",
                            "borderRight": "solid #d9d9d9 0.5pt",
                            "borderBottom": "solid #d9d9d9 0.5pt",
                            "borderTop": "solid #d9d9d9 0.5pt",
                            "verticalAlign": "top",
                            "backgroundColor": "#baead7",
                            "padding": "5pt 5pt 5pt 5pt",
                            "overflow": "hidden",
                            "overflowWrap": "break-word"
                        }}>
                            <p dir="ltr" style={{"lineHeight": "1.44", "marginTop": "0pt", "marginBottom": "0pt"}}><span
                                style={{
                                    "fontSize": "11pt",
                                    "fontFamily": "'Helvetica Neue',sans-serif",
                                    "color": "#000000",
                                    "backgroundColor": "transparent",
                                    "fontWeight": "700",
                                    "fontStyle": "normal",
                                    "fontVariant": "normal",
                                    "textDecoration": "none",
                                    "verticalAlign": "baseline",
                                    "whiteSpace": "pre-wrap"
                                }}>Ticket #</span>
                            </p>
                        </td>
                        <td style={{
                            "borderLeft": "solid #d9d9d9 0.5pt",
                            "borderRight": "solid #d9d9d9 0.5pt",
                            "borderBottom": "solid #d9d9d9 0.5pt",
                            "borderTop": "solid #d9d9d9 0.5pt",
                            "verticalAlign": "top",
                            "backgroundColor": "#baead7",
                            "padding": "5pt 5pt 5pt 5pt",
                            "overflow": "hidden",
                            "overflowWrap": "break-word"
                        }}>
                            <p dir="ltr" style={{"lineHeight": "1.44", "marginTop": "0pt", "marginBottom": "0pt"}}><span
                                style={{
                                    "fontSize": "11pt",
                                    "fontFamily": "'Helvetica Neue',sans-serif",
                                    "color": "#000000",
                                    "backgroundColor": "transparent",
                                    "fontWeight": "700",
                                    "fontStyle": "normal",
                                    "fontVariant": "normal",
                                    "textDecoration": "none",
                                    "verticalAlign": "baseline",
                                    "whiteSpace": "pre-wrap"
                                }}>Description</span>
                            </p>
                        </td>
                        <td style={{
                            "borderLeft": "solid #d9d9d9 0.5pt",
                            "borderRight": "solid #d9d9d9 0.5pt",
                            "borderBottom": "solid #d9d9d9 0.5pt",
                            "borderTop": "solid #d9d9d9 0.5pt",
                            "verticalAlign": "top",
                            "backgroundColor": "#baead7",
                            "padding": "5pt 5pt 5pt 5pt",
                            "overflow": "hidden",
                            "overflowWrap": "break-word"
                        }}>
                            <p dir="ltr" style={{"lineHeight": "1.44", "marginTop": "0pt", "marginBottom": "0pt"}}><span
                                style={{
                                    "fontSize": "11pt",
                                    "fontFamily": "'Helvetica Neue',sans-serif",
                                    "color": "#000000",
                                    "backgroundColor": "transparent",
                                    "fontWeight": "700",
                                    "fontStyle": "normal",
                                    "fontVariant": "normal",
                                    "textDecoration": "none",
                                    "verticalAlign": "baseline",
                                    "whiteSpace": "pre-wrap"
                                }}>Status</span>
                            </p>
                        </td>
                        <td style={{
                            "borderLeft": "solid #d9d9d9 0.5pt",
                            "borderRight": "solid #d9d9d9 0.5pt",
                            "borderBottom": "solid #d9d9d9 0.5pt",
                            "borderTop": "solid #d9d9d9 0.5pt",
                            "verticalAlign": "top",
                            "backgroundColor": "#baead7",
                            "padding": "5pt 5pt 5pt 5pt",
                            "overflow": "hidden",
                            "overflowWrap": "break-word"
                        }}>
                            <p dir="ltr" style={{"lineHeight": "1.44", "marginTop": "0pt", "marginBottom": "0pt"}}><span
                                style={{
                                    "fontSize": "11pt",
                                    "fontFamily": "'Helvetica Neue',sans-serif",
                                    "color": "#000000",
                                    "backgroundColor": "transparent",
                                    "fontWeight": "700",
                                    "fontStyle": "normal",
                                    "fontVariant": "normal",
                                    "textDecoration": "none",
                                    "verticalAlign": "baseline",
                                    "whiteSpace": "pre-wrap"
                                }}>Report date</span>
                            </p>
                        </td>
                        <td style={{
                            "borderLeft": "solid #d9d9d9 0.5pt",
                            "borderRight": "solid #d9d9d9 0.5pt",
                            "borderBottom": "solid #d9d9d9 0.5pt",
                            "borderTop": "solid #d9d9d9 0.5pt",
                            "verticalAlign": "top",
                            "backgroundColor": "#baead7",
                            "padding": "5pt 5pt 5pt 5pt",
                            "overflow": "hidden",
                            "overflowWrap": "break-word"
                        }}>
                            <p dir="ltr" style={{"lineHeight": "1.44", "marginTop": "0pt", "marginBottom": "0pt"}}><span
                                style={{
                                    "fontSize": "11pt",
                                    "fontFamily": "'Helvetica Neue',sans-serif",
                                    "color": "#000000",
                                    "backgroundColor": "transparent",
                                    "fontWeight": "700",
                                    "fontStyle": "normal",
                                    "fontVariant": "normal",
                                    "textDecoration": "none",
                                    "verticalAlign": "baseline",
                                    "whiteSpace": "pre-wrap"
                                }}>Solve Date</span>
                            </p>
                        </td>
                    </tr>
                    {tickets.map(ticket => <TableRow id={ticket.id} ticket={ticket}/>)}
                    </tbody>
                </table>
            </div>
        </b>
    )
}

export default Table;
import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import Table from "../components/Table";
import {renderToString} from "react-dom/server";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import {getOrganizationsData, getTicketData} from "../data/ZendeskData";
import {
    Heading,
    Stack,
    useColorModeValue,
    useToast,
    Box,
    FormControl,
    FormLabel,
    ButtonGroup,
    Select,
    Button
} from '@chakra-ui/react'
import HowToAccordion from "../components/HowToAccordion";

function Home() {
    const [searchParams] = useSearchParams({});
    const [cookies, setCookie, removeCookie] = useCookies(['zendesk_token']);
    const [tickets, setTickets] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [selectedOrganizationId, setSelectedOrganizationId] = useState("");
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    const toast = useToast()

    const zendeskUrl = process.env.REACT_APP_ZENDESK_URL;
    const zendeskClientId = process.env.REACT_APP_ZENDESK_CLIENT_ID;

    useEffect(() => {
        if (cookies.zendesk_token) {
            getOrganizations();
        }

    }, [cookies.zendesk_token])

    useEffect(() => {
        processZendeskAccessToken();
    }, [searchParams])

    function toastSuccess(title) {
        toast({
            title: title, status: 'success', duration: 5000
        })
    }

    function toastWarning(title) {
        toast({
            title: title, status: 'warning', duration: 5000
        })
    }

    function toastError(title) {
        toast({
            title: title, status: 'error', duration: 5000
        })
    }

    function processZendeskAccessToken() {
        let url = document.location.href;
        if (url.includes('access_token=')) {
            const access_token = url.substring(url.indexOf("access_token="), url.indexOf("&")).split('=')[1];
            let cookieExpiryDate = new Date();
            cookieExpiryDate.setDate(cookieExpiryDate.getDate() + 1);
            setCookie('zendesk_token', access_token, {sameSite: "strict", path: "/", expires: cookieExpiryDate})
            window.history.pushState({}, null, "/");
        }
    }

    function getOrganizations() {
        getOrganizationsData(cookies.zendesk_token).then(data => {
            setOrganizations(data);
        }).catch(() => {
            toastError("Error retrieving organizations");
        });
    }

    function getTickets() {
        if (!cookies.zendesk_token) {
            toastError("Authenticate with Zendesk");
            return;
        }

        if (!selectedOrganizationId) {
            toastError("No organization selected");
            return;
        }

        if (dateRange == null) {
            toastError("Select a date range");
            return;
        }

        getTicketData(cookies.zendesk_token, selectedOrganizationId, dateRange[0], dateRange[1]).then(data => {
            if(data === null || data.length === 0) {
                toastWarning("No tickets returned from Zendesk");
            }
            setTickets(data);
        }).catch(() => {
            toastError("Error retrieving tickets");
        })
    }


    function logoutClicked() {
        toastSuccess("Logged out");
        return removeCookie('zendesk_token');
    }

    function authorizeZendeskClicked() {
        return window.location.replace(`${zendeskUrl}/oauth/authorizations/new?response_type=token&client_id=${zendeskClientId}&scope=read`);
    }

    function copyTableClicked() {
        let tableHtml = renderToString(<Table tickets={tickets}/>)
        const blobInput = new Blob([tableHtml], {type: 'text/html'});
        const clipboardItemInput = new window.ClipboardItem({'text/html': blobInput});
        navigator.clipboard.write([clipboardItemInput]);
        toastSuccess("Copied to clipboard!");
    }

    function setLastMonth() {
        const now = new Date();
        const firstDay = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        const lastDay = new Date(now.getFullYear(), now.getMonth(), 0);
        setDateRange([firstDay, lastDay]);
    }

    function setCurrentMonth() {
        const now = new Date();
        const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
        const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        setDateRange([firstDay, lastDay]);
    }

    return (<div style={{width: "80%", margin: "auto", marginTop: "20px"}}>
            <Stack align={'center'} mx={'auto'} py={12}>
                <Heading fontSize={'4xl'}>SLA Report Zendesk Table Generator</Heading>
            </Stack>
            <HowToAccordion/>
            <Box
                rounded={'lg'}
                bg={useColorModeValue('white', 'gray.700')}
                boxShadow={'lg'}
                p={8}
                mt={2}
                mb={5}>
                <Stack spacing={4}>
                    <FormControl>
                        {!cookies.zendesk_token && <Button
                            onClick={authorizeZendeskClicked}>Authenticate Zendesk
                        </Button>}
                        {cookies.zendesk_token && <Button onClick={logoutClicked}>Logout</Button>}
                    </FormControl>
                    <FormControl>
                        <FormLabel>
                            Date range
                        </FormLabel>
                        <DateRangePicker onChange={setDateRange} value={dateRange}
                                         style={{height: "2rem", marginRight: "1rem"}}/>
                        <ButtonGroup style={{marginLeft: "10px"}}>
                            <Button onClick={setLastMonth}>Last month</Button>
                            <Button onClick={setCurrentMonth}>Current month</Button>
                        </ButtonGroup>
                    </FormControl>
                    <FormControl>
                        <FormLabel>
                            Organization
                        </FormLabel>
                        <Select placeholder='Select organization' value={selectedOrganizationId} onChange={(event => {
                            setSelectedOrganizationId(event.target.value)
                        })}>
                            {organizations.map((o) => (<option key={o.id} value={o.id}>{o.name}</option>))}
                        </Select>
                    </FormControl>
                    <ButtonGroup>
                        <Button onClick={getTickets}>Get Tickets</Button>
                        <Button onClick={copyTableClicked}>Copy Table</Button>
                    </ButtonGroup>
                    {tickets.length > 0 &&  <Table tickets={tickets}/>}
                </Stack>
            </Box>
        </div>)

}

export default Home;
import axios from "axios";

const zendeskUrl = process.env.REACT_APP_ZENDESK_URL;

export function getOrganizationsData(zendeskToken) {
    const config = {
        headers: {Authorization: `Bearer ${zendeskToken}`}
    };
    return axios.get(`${zendeskUrl}/api/v2/organizations`, config).then(data => {
        return data.data.organizations;
    })
}

function keepTicket(ticket, startDate, endDate) {
    if(ticket.solved_at == null) {
        return true;
    }

    if(ticket.created_at >= startDate && ticket.created_at <= endDate) {
        return true;
    }

    if(ticket.updated_at >= startDate && ticket.created_at <= endDate){
        return true;
    }

    return false;
}

export function getTicketData(zendeskToken, organizationId, startDate, endDate) {
    const config = {
        headers: {Authorization: `Bearer ${zendeskToken}`}
    };

    return axios.get(`${zendeskUrl}/api/v2/organizations/${organizationId}/tickets`, config).then(data => {
        const tickets = []
        data.data.tickets.forEach(ticket => {
            let type = ticket.custom_fields[0].value;
            if (type != null) {
                if ((type[0] === 's' || type[0] === 'c')) {
                    type = type.split('_')[0];
                } else {
                    type = type.replace('_', ' ');
                }
            }
            let cleanTicket = {
                id: ticket.id,
                name: ticket.subject,
                status: ticket.status.toUpperCase(),
                created_at: new Date(ticket.created_at),
                updated_at: new Date(ticket.updated_at),
                solved_at: tickets.status === 'solved' || ticket.status === 'closed' ? new Date(ticket.updated_at) : null,
                type: type ? type.toUpperCase() : ""
            }

            if(keepTicket(cleanTicket, startDate, endDate)) {
                tickets.push(cleanTicket);
            }
        })
        return tickets;
    })
}


